import React, { useCallback, useEffect, useRef } from 'react';

// function returnng points postion ,y
export interface LineStepsProps extends React.SVGProps<SVGSVGElement> {
  onPointsCalculated: (points: ButtonPointsProps[]) => void;
}

export interface ButtonPointsProps {
  x: number;
  y: number;
}

const Line3Steps: React.FC<LineStepsProps> = ({
  onPointsCalculated,
  ...props
}) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const point1 = useRef<SVGCircleElement>(null);
  const point2 = useRef<SVGCircleElement>(null);
  const point3 = useRef<SVGCircleElement>(null);

  const handlePointsCalculated = useCallback(() => {
    if (point1.current && point2.current && point3.current) {
      const points = [point1.current, point2.current, point3.current];
      const newPositionPoints: ButtonPointsProps[] = [];

      points.forEach(point => {
        if (!point.ownerSVGElement) {
          return;
        }

        const svgPoint = point.ownerSVGElement.createSVGPoint();
        svgPoint.x = point.cx.baseVal.value;
        svgPoint.y = point.cy.baseVal.value;
        const ctm = point.getCTM();

        if (!ctm) {
          return;
        }
        const transformedPoint = svgPoint.matrixTransform(ctm);

        newPositionPoints.push({
          x: transformedPoint.x,
          y: transformedPoint.y,
        });
      });

      onPointsCalculated(newPositionPoints);
    }
  }, [onPointsCalculated]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    handlePointsCalculated();

    if (svgRef.current) {
      const resizeObserver = new ResizeObserver(() => {
        handlePointsCalculated();
      });
      resizeObserver.observe(svgRef.current);
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [handlePointsCalculated]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width={418.007}
      height={96.267}
      viewBox="0 0 110.598 25.471"
      ref={svgRef}
      {...props}
    >
      <g
        style={{
          fill: 'none',
        }}
        transform="matrix(.26458 0 0 .26458 -1.267 -66.132)"
      >
        <path
          fill="#1e88e5"
          d="M376.37 346.214c-25.598 0-46.417-21.084-46.417-46.993v-1.817c0-19.152-15.395-34.738-34.313-34.738-18.918 0-34.313 15.58-34.313 34.738v1.36c0 25.915-20.825 46.992-46.417 46.992-25.591 0-46.417-21.084-46.417-46.992v-1.817c0-19.153-15.395-34.738-34.312-34.738h-1.795c-18.918 0-34.313 15.579-34.313 34.738v1.817c0 25.915-20.826 46.992-46.417 46.992h-.445c-25.599 0-46.424-21.084-46.424-46.992h12.11c0 19.152 15.39 34.738 34.314 34.738h.445c18.918 0 34.313-15.586 34.313-34.738v-1.817c0-25.916 20.819-47 46.417-47h1.795c25.598 0 46.417 21.084 46.417 46.993v1.817c0 19.152 15.395 34.738 34.312 34.738 18.918 0 34.313-15.586 34.313-34.738v-1.36c0-25.915 20.826-46.992 46.417-46.992 25.592 0 46.417 21.084 46.417 46.993v1.816c0 19.153 15.395 34.739 34.313 34.739 18.918 0 34.313-15.586 34.313-34.739h12.11c0 25.916-20.825 46.993-46.416 46.993z"
        />
        <circle
          ref={point1}
          cx={51.65}
          cy={298.977}
          r={1.061}
          style={{
            fill: '#1e88e5',
            fillOpacity: 0,
            strokeWidth: 20.48,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            paintOrder: 'stroke fill markers',
          }}
        />
        <circle
          ref={point2}
          cx={214.591}
          cy={298.977}
          r={1.061}
          style={{
            fill: '#1e88e5',
            fillOpacity: 0,
            strokeWidth: 20.48,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            paintOrder: 'stroke fill markers',
          }}
        />
        <circle
          ref={point3}
          cx={376.381}
          cy={298.977}
          r={1.061}
          style={{
            fill: '#1e88e5',
            fillOpacity: 0,
            strokeWidth: 20.48,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            paintOrder: 'stroke fill markers',
          }}
        />
      </g>
    </svg>
  );
};
export default Line3Steps;
