import React from 'react';
import { AuthProvider } from './auth';
import { ModalProvider } from './modal';
import { ToastProvider } from './toast';
import { TabProvider } from './tab';
import setLinks from '../links';
import { FeedbackProvider } from './feedback';

const AppProvider: React.FC = ({ children }) => {
  setLinks();

  return (
    <FeedbackProvider>
      <ToastProvider>
        <AuthProvider>
          <ModalProvider>
            <TabProvider>{children}</TabProvider>
          </ModalProvider>
        </AuthProvider>
      </ToastProvider>
    </FeedbackProvider>
  );
};

export default AppProvider;
