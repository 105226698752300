import React from 'react';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  LoadingWrapper,
  ListWrapper,
  MainContent,
  PlaylistsContent,
  ProgressList,
  ShimmerEffect,
  MainHeadContent,
} from './styles';
import { ArrowBackIcon } from '../../../../assets/svg/SVGComponents';

// import { Container } from './styles';

const LoadingState: React.FC = () => {
  const { t } = useTranslation();
  return (
    <LoadingWrapper>
      <MainContent>
        <MainHeadContent>
          <Link to={-1 as any}>
            <ArrowBackIcon
              width="1.22rem"
              stroke="#1E88E5"
              strokeWidth={3}
              fill="none"
            />
            {t('navlinks.back')}
          </Link>
          <ShimmerEffect className="loading-Projects" />
          <ShimmerEffect className="loading-title" />
        </MainHeadContent>

        <ListWrapper>
          <ShimmerEffect className="card-chapter" />
          <ShimmerEffect className="card-chapter" />
          <ShimmerEffect className="card-chapter" />
          <ShimmerEffect className="card-chapter" />
        </ListWrapper>
      </MainContent>

      <PlaylistsContent>
        <ProgressList>
          <ShimmerEffect className="progress-title" />
          <ShimmerEffect className="progress-card" />
          <ShimmerEffect className="progress-card" />
          <ShimmerEffect className="progress-card" />
          <ShimmerEffect className="progress-card" />
        </ProgressList>
      </PlaylistsContent>
    </LoadingWrapper>
  );
};

export default LoadingState;
