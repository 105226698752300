import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  padding-top: 40%;

  > iframe {
    width: 71.3%;
    height: 35rem;
    border: none;
    border-radius: 1rem;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
`;
