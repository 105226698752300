/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import { StylesConfig } from 'react-select';

interface SubjectTagProps {
  borderColor: string;
}

export const MainContainer = styled.div`
  width: 65%;
  font-size: 1rem;

  form {
    > header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 2.72rem;

      > h3 {
        font-family: 'RedHatdisplay-Bold';
        font-size: 1.56rem;
      }
    }

    > h4 {
      font-size: 0.89rem;
      margin-bottom: 1.5%;
    }

    > h4 + div {
      margin-bottom: 2.11rem;
    }

    > header {
      > .primary-button {
        margin-left: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        color: #fff;
        line-height: 1.28rem;
        padding: 0.77rem 1rem;
        border-radius: 0.55rem;
        min-width: 10.57rem;
        min-height: 2.82rem;
        box-shadow: 0px 3px 6px rgba(255, 152, 0, 0.3);
      }
    }

    > p {
      margin-top: 6%;
      display: flex;
      justify-content: start;
      align-items: center;
      font-family: 'RedHatDisplay-Regular';
      font-size: 0.8rem;
      > div {
        margin-right: 0.5rem;
      }

      button {
        margin-left: 0.5rem;
        font-family: 'RedHatDisplay-Regular';
        font-size: 0.8rem;
        color: #1e88e5;
      }
    }

    .checkbox {
      margin-bottom: 1.5%;

      > button {
        margin-left: 0.5rem;
        font-family: 'RedHatDisplay-Regular';
        font-size: 0.8rem;
        color: #1e88e5;
      }
    }
  }
`;

export const RedefinePassword = styled.div`
  display: flex;
  justify-content: space-between;

  > h4 {
    font-size: 0.89rem;
    margin-bottom: 1.5%;
  }

  & + div {
    margin-bottom: 2.11rem;
  }

  > button {
    font-family: 'RedHatDisplay-Regular';
    font-size: 0.89rem;
    line-height: 1.11rem;
    color: #1e88e5;
    text-decoration: none;
    opacity: 0.8;
    transition: 0.3s;

    &:hover {
      opacity: 1;
    }
  }
`;

export const SubjectsTagsContainer = styled.ul`
  display: flex;
  width: 60%;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 1rem 0 2rem;
`;

export const SubjectTag = styled.li<SubjectTagProps>`
  display: flex;
  align-items: center;
  padding: 0.55rem 0.83rem;
  margin-bottom: 1rem;
  border-radius: 1.1rem;
  border: 1px solid ${props => props.borderColor};
  font-family: 'RedHatDisplay-Regular';
  font-size: 0.88rem;
  line-height: 1.11rem;

  > svg {
    cursor: pointer;
    margin-left: 0.67rem;
  }

  &:not(:last-child) {
    margin-right: 1rem;
  }
`;

export const genderSelectStyles: StylesConfig = {
  indicatorSeparator: (providedStyles: unknown) => ({
    ...providedStyles,
    display: 'none',
  }),
  container: providedStyles => ({
    ...providedStyles,
    fontFamily: 'RedHatDisplay-Regular',
    fontSize: '1rem',
  }),
  control: (
    providedStyles: unknown,
    state: { isFocused: unknown; menuIsOpen: unknown },
  ) => ({
    ...providedStyles,
    paddingLeft: '1.1rem',
    margin: 0,
    height: 'auto',
    borderRadius: '0.56rem',
    border:
      state.isFocused || state.menuIsOpen
        ? '1px solid rgba(255, 152, 0, 1)'
        : '1px solid #e2e2e2',
  }),
  valueContainer: (providedStyles: unknown) => ({
    ...providedStyles,
    padding: '0 1.167rem;',
    height: '3.167rem',
    color: '#1e88e5',
  }),
  input: providedStyles => ({
    ...providedStyles,
  }),
  option: (providedStyles: unknown, state: { isFocused: unknown }) => ({
    ...providedStyles,
    color: '#1e88e5',
    background: state.isFocused ? 'rgba(226, 226, 226, 0.2)' : '#fff',
  }),
  singleValue: providedStyles => ({
    ...providedStyles,
    color: '#1e88e5',
  }),
  placeholder: providedStyles => ({
    ...providedStyles,
  }),
  dropdownIndicator: (
    providedStyles: unknown,
    state: { isFocused: unknown },
  ) => ({
    ...providedStyles,
    margin: 0,
    padding: 0,
    stroke: state.isFocused ? 'rgba(255, 152, 0, 1)' : '#1e88e5',
  }),
  indicatorsContainer: (providedStyles: unknown) => ({
    ...providedStyles,
    height: 'auto',
    margin: 0,
    padding: '0px 2rem',
  }),
};

export const SwitchContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  > span {
    font-family: 'MarkPro';
    font-size: 0.88rem;
    line-height: 1.5rem;
    margin-bottom: 0.8rem;
  }
`;

export const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 24px;
    width: 24px;
    left: 3px;
    bottom: 3px;
    background-color: #fff;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }

  input:checked + .slider {
    background: linear-gradient(
      to right,
      #ec775d 0%,
      #ff8da7 51%,
      #ec775d 100%
    );
  }

  input:focus + .slider {
    box-shadow: 0 0 1px
      linear-gradient(to right, #ec775d 0%, #ff8da7 51%, #ec775d 100%);
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(30px);
    -ms-transform: translateX(30px);
    transform: translateX(30px);
  }

  .slider.round {
    border-radius: 30px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 36px;
  padding-top: 32px;

  > .primary-button {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    color: #fff;
    line-height: 1.28rem;
    padding: 0.77rem 1rem;
    border-radius: 0.55rem;
    min-width: 10.57rem;
    min-height: 2.82rem;
    box-shadow: 0px 3px 6px rgba(255, 159, 0, 0.3);
  }
`;
