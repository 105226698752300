import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  AbilityColumn,
  AbilityMap,
  ContentWrapper,
  Fill,
  HeaderContent,
  InfoBar,
  MainContent,
  Profile,
  Progress,
  ProgressionSection,
  Trails,
  HeaderTrails,
  UserAvatar,
  WelcomeSection,
  JourneyRow,
  JourneyItem,
  JourneyContent,
  JourneyDetails,
  Tag,
  JourneyCard,
  IllustrationWrapper,
  BnccSection,
  SearchInput,
  ChapterCard,
  ChapterGrid,
  IconSection,
  ContentSection,
  SearchArea,
  ShimmerEffect,
  AvatarWrapper,
} from './styles';
import {
  ArrowBackIcon,
  BulletedIcon,
  ChapterIcon,
  CrossIcon,
  ProjectsIcon,
  SearchIcon,
  SimulationIcon,
} from '../../../assets/svg/SVGComponents';
import api from '../../../services/api';
import IRequestError from '../../../dtos/IRequestError';
import { useToast } from '../../../hooks/toast';
import { IHardwareProps, useAuth } from '../../../hooks/auth';
import IChapterProps from '../../../dtos/IChapterProps';

interface ITagProps {
  title: string;
  color: string;
}

interface ISearchChapterProps extends IChapterProps {
  trailSlug: string;
}

// import { Container } from './styles';

const HardwareProfile: React.FC = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { hardware } = useParams();
  const [linkSimulator, setLinkSimulator] = useState('');
  const [chapterVisual, setChapterVisual] = useState(false);
  const [inputText, setInputText] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const { isSessionExpired } = useAuth();
  const { addToast } = useToast();
  const [numberRows, setNumberRows] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [hardwareData, setHardwareData] = useState<IHardwareProps | null>(null);
  const [chapterData, setChapterData] = useState<ISearchChapterProps[] | null>(
    null,
  );
  const [filterData, setFilterData] = useState<ISearchChapterProps[] | null>(
    null,
  );
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (chapterData) {
      setNumberRows(chapterData.length / 3);
    }
  }, [chapterData, setNumberRows]);

  useEffect(() => {
    api
      .get(`/hardwares/${hardware}`)
      .then(response => {
        setHardwareData(response.data.hardware);
        setProgress(response.data.progress);
        setIsLoading(false);
      })
      .catch(async (err: IRequestError) => {
        if (!(await isSessionExpired(err))) {
          addToast({
            type: 'error',
            expression: 'Ops.',
            description: t('toasts.errors.load_data_fail'),
          });
        }
      });
  }, [addToast, hardware, isSessionExpired, t]);

  useEffect(() => {
    if (hardwareData) {
      const reduceChapters = hardwareData.trails.reduce(
        (chapters: ISearchChapterProps[], trail) => {
          // Adicionando o slug da trilha a cada capítulo
          const trailChaptersWithSlug = trail.chapters.map(chapter => ({
            ...chapter,
            trailSlug: trail.slug, // Supondo que `trail` tem uma propriedade `slug`
          }));

          return chapters.concat(trailChaptersWithSlug);
        },
        [],
      );

      setChapterData(reduceChapters);
    }
  }, [hardwareData]);

  const handleSimulationLink = useCallback(() => {
    if (hardware === 'fuzzy_bits') {
      setLinkSimulator('https://play.fuzzymakers.com');
    }
    if (hardware === 'micro_bit') {
      setLinkSimulator(`${pathname}/makecode`);
    }
    if (hardware === 'makey_makey') {
      setLinkSimulator(
        `https://scratch.mit.edu/search/projects?q=makey%20makey`,
      );
    }
  }, [hardware, pathname]);

  useEffect(() => {
    handleSimulationLink();
  }, [handleSimulationLink]);

  const handleTagsTrails = useCallback(
    (slug: string): ITagProps[] => {
      let tags: ITagProps[] = [];
      if (slug) {
        tags = t(`trails.${slug}.tags`, { returnObjects: true });
      }

      return [];
    },
    [t],
  );

  const handleAbilitiesTrails = useCallback(
    (slug: string): ITagProps[] => {
      if (slug) {
        return t(`trails.${slug}.abilities`, { returnObjects: true });
      }

      return [];
    },
    [t],
  );

  const handleSubjectsTrails = useCallback(
    (slug: string): ITagProps[] => {
      if (slug) {
        return t(`trails.${slug}.subjects`, { returnObjects: true });
      }

      return [];
    },
    [t],
  );

  // Pesquisar por projetos

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);
  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setInputText(event.target.value);
      setChapterVisual(true);
    },
    [],
  );

  useEffect(() => {
    if (chapterData) {
      if (inputText.length) {
        const searched = chapterData.filter(template =>
          template.title.toLowerCase().includes(inputText.toLocaleLowerCase()),
        );
        setFilterData(searched);
      } else {
        setChapterVisual(false);
        setFilterData(chapterData);
      }
    }
  }, [chapterData, inputText]);

  const clearInput = useCallback(() => {
    setInputText('');
  }, []);

  const handleButtonVisualChapter = useCallback(() => {
    setChapterVisual(!chapterVisual);
  }, [chapterVisual]);

  const handleLinkTutorial = useCallback(
    (chapterItem: ISearchChapterProps): string => {
      let link = '';
      if (chapterItem.type === 'activity') {
        link = `/projects/${chapterItem.trailSlug}/${chapterItem.id}/activity/${chapterItem.activities[0].slug}`;
      }
      if (chapterItem.type === 'lesson') {
        link = `/videos/${chapterItem.lessons[0].journey_slug}/playlists/${chapterItem.lessons[0].playlist_slug}/lessons/${chapterItem.lessons[0].slug}`;
      }

      return link;
    },
    [],
  );

  // sort by order and filter by deleted
  const listOfTrails = useMemo(() => {
    if (hardwareData) {
      const trailsNotDeleted = hardwareData.trails.filter(
        trail => trail.deleted === false,
      );

      return trailsNotDeleted.sort((a, b) => {
        return a.order - b.order;
      });
    }
    return undefined;
  }, [hardwareData]);

  return (
    <ContentWrapper>
      <HeaderContent>
        <Link to={-1 as any}>
          <ArrowBackIcon
            width="1.22rem"
            stroke="#1e88e5"
            strokeWidth={3}
            fill="none"
          />
          {t('navlinks.back')}
        </Link>
        <WelcomeSection>
          <Profile>
            <AvatarWrapper>
              <UserAvatar>
                {!hardwareData && isLoading && (
                  <ShimmerEffect className="shimmer-avatar" />
                )}
                {!!hardwareData && (
                  <img src={hardwareData.avatar_url} alt="logo-hardware" />
                )}
              </UserAvatar>
            </AvatarWrapper>
            <span data-testid="hardware-name">
              {t(`hardware.${hardware}.title`)}
            </span>
          </Profile>
          <ProgressionSection>
            <span>{t('spans.progress')}</span>
            <p>{!progress ? '-' : `${progress.toFixed(0)}%`}</p>
          </ProgressionSection>

          {hardware === 'fuzzy_bits' && (
            <button type="button">
              <a href={linkSimulator} target="_blank" rel="noopener noreferrer">
                <SimulationIcon width="1.2em" stroke="#fff" strokeWidth={1.5} />
                <span>{t('buttons.access_simulator')}</span>
              </a>
            </button>
          )}
          {hardware === 'micro_bit' && (
            <button type="button">
              <Link to="/makecode">
                <SimulationIcon width="1.2em" stroke="#fff" strokeWidth={1.5} />
                <span>{t('buttons.access_simulator')}</span>
              </Link>
            </button>
          )}
          {hardware === 'makey_makey' && (
            <button type="button">
              <a href={linkSimulator} target="_blank" rel="noopener noreferrer">
                <SimulationIcon width="1.2em" stroke="#fff" strokeWidth={1.5} />
                <span>{t('buttons.access_simulator')}</span>
              </a>
            </button>
          )}
        </WelcomeSection>
      </HeaderContent>
      <MainContent>
        <AbilityMap>
          <h1>{t('h1.ability_map')}</h1>
          <div>
            <AbilityColumn>
              <li>
                <InfoBar progress={t(`hardware.${hardware}.ability_map.logic`)}>
                  <BulletedIcon width="0.9rem" />
                  <h3>{t('h3.hardware_programming_logic')}</h3>
                </InfoBar>

                <Progress>
                  <Fill
                    progress={t(`hardware.${hardware}.ability_map.logic`)}
                  />
                </Progress>
              </li>
              <li>
                <InfoBar
                  progress={t(`hardware.${hardware}.ability_map.electronic`)}
                >
                  <BulletedIcon width="0.9rem" />
                  <h3>{t('h3.hardware_electronic')}</h3>
                </InfoBar>

                <Progress>
                  <Fill
                    progress={t(`hardware.${hardware}.ability_map.electronic`)}
                  />
                </Progress>
              </li>
            </AbilityColumn>
            <AbilityColumn>
              <li>
                <InfoBar progress={t(`hardware.${hardware}.ability_map.maker`)}>
                  <BulletedIcon width="0.9rem" />
                  <h3>{t('h3.hardware_maker')}</h3>
                </InfoBar>

                <Progress>
                  <Fill
                    progress={t(`hardware.${hardware}.ability_map.maker`)}
                  />
                </Progress>
              </li>
              <li>
                <InfoBar
                  progress={t(`hardware.${hardware}.ability_map.robotics`)}
                >
                  <BulletedIcon width="0.9rem" />
                  <h3>{t('h3.hardware_robotic')}</h3>
                </InfoBar>

                <Progress>
                  <Fill
                    progress={t(`hardware.${hardware}.ability_map.robotics`)}
                  />
                </Progress>
              </li>
            </AbilityColumn>
            <AbilityColumn>
              <li>
                <InfoBar progress={t(`hardware.${hardware}.ability_map.games`)}>
                  <BulletedIcon width="0.9rem" />
                  <h3>{t('h3.hardware_games')}</h3>
                </InfoBar>

                <Progress>
                  <Fill
                    progress={t(`hardware.${hardware}.ability_map.games`)}
                  />
                </Progress>
              </li>
              <li>
                <InfoBar
                  progress={t(`hardware.${hardware}.ability_map.coding`)}
                >
                  <BulletedIcon width="0.9rem" />
                  <h3>{t('h3.hardware_coding')}</h3>
                </InfoBar>

                <Progress>
                  <Fill
                    progress={t(`hardware.${hardware}.ability_map.coding`)}
                  />
                </Progress>
              </li>
            </AbilityColumn>
          </div>
        </AbilityMap>
        <Trails>
          <HeaderTrails>
            <h1>{t('h1.learning_trails')}</h1>

            <SearchArea>
              <button type="button" onClick={handleButtonVisualChapter}>
                {!chapterVisual
                  ? 'Visualização por projetos'
                  : 'Visualização por trilhas'}
              </button>
              <SearchInput isFocused={isFocused}>
                <SearchIcon
                  height="1.72rem"
                  fill="none"
                  stroke="#1e88e5"
                  strokeWidth={2}
                />
                <input
                  placeholder={t('placeholders.search_templates')}
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                  value={inputText}
                  type="text"
                />
                <button type="button" onClick={clearInput}>
                  <CrossIcon height="0.6rem" fill="none" strokeWidth={1} />
                </button>
              </SearchInput>
            </SearchArea>
          </HeaderTrails>

          <JourneyRow>
            {listOfTrails &&
              !chapterVisual &&
              listOfTrails.map(trail => (
                <JourneyItem key={trail.id}>
                  <JourneyCard to={`/trails/${trail.slug}/chapters`}>
                    <JourneyContent>
                      <header>
                        {handleTagsTrails(trail.slug).map(tag => (
                          <Tag key={tag.title} color={tag.color}>
                            {tag.title}
                          </Tag>
                        ))}
                      </header>
                      <h1>{t(`trails.${trail.slug}.title`)}</h1>
                      <p>{t(`trails.${trail.slug}.description`)}</p>
                      <JourneyDetails>
                        <div>
                          <p>
                            <ChapterIcon
                              width="2.2rem"
                              fill="none"
                              stroke="#1e88e5"
                              strokeWidth={2.5}
                            />
                          </p>
                          <span>
                            <h2>{trail.chapters.length}</h2>
                            <p>{t(`trails.${trail.slug}.chapters_title`)}</p>
                          </span>
                        </div>
                        <div>
                          <p>
                            <ProjectsIcon
                              width="2.2rem"
                              stroke="#1e88e5"
                              strokeWidth={2.5}
                            />
                          </p>
                          <span>
                            <h2>
                              {trail.chapters.reduce((sum, chapter) => {
                                return sum + chapter.activities.length;
                              }, 0)}
                            </h2>
                            <p>{t(`trails.${trail.slug}.activities_title`)}</p>
                          </span>
                        </div>
                      </JourneyDetails>
                    </JourneyContent>
                    <IllustrationWrapper>
                      <img src={trail.image_url} alt="img-trail" />
                    </IllustrationWrapper>
                  </JourneyCard>
                  <BnccSection>
                    <div>
                      <h3>{t('h3.bncc_abilities')}</h3>
                      <div>
                        {handleAbilitiesTrails(trail.slug).map(
                          (ability, index) => (
                            <span key={index}>{ability}</span>
                          ),
                        )}
                      </div>
                    </div>
                    {/* precisa mudar de cor as divs */}
                    <div>
                      <h3>{t('h3.bncc_subjects')}</h3>
                      <div>
                        {handleSubjectsTrails(trail.slug).map(ability => (
                          <span>{ability}</span>
                        ))}
                      </div>
                    </div>
                  </BnccSection>
                </JourneyItem>
              ))}

            {!hardwareData && isLoading && (
              <ShimmerEffect className="shimmer-card" />
            )}
          </JourneyRow>

          {hardwareData && filterData && !!chapterVisual && (
            <ChapterGrid numberRows={numberRows}>
              {filterData.map(chapter => (
                <ChapterCard to={handleLinkTutorial(chapter)}>
                  <IconSection>
                    <img src={chapter.icon_url} alt="illustration" />
                  </IconSection>
                  <ContentSection>
                    <h1>{t(`chapters.${chapter.slug}.title`)}</h1>
                    <p>{t(`chapters.${chapter.slug}.short_description`)}</p>
                  </ContentSection>
                </ChapterCard>
              ))}
            </ChapterGrid>
          )}
        </Trails>
      </MainContent>
    </ContentWrapper>
  );
};

export default HardwareProfile;
