import styled, { css } from 'styled-components';

interface StepProps {
  accomplished: boolean;
  positionX?: number;
  positionY?: number;
}

interface StepBarProps {
  steps: number;
}

export const Container = styled.div`
  padding: 0;
  width: 100%;
  position: relative;
`;

export const Background = styled.div`
  width: 100%;
  height: auto;
`;

export const Steps = styled.div<StepBarProps>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Step = styled.div<StepProps>`
  position: absolute;
  left: ${props => `${props.positionX}px`};
  top: ${props => `${props.positionY}px`};
  transform: translate(-50%, -50%);

  > span {
    width: 250%;
    top: -2.2rem;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    position: absolute;
    font-family: 'RedHatDisplay-ExtraBold';
    font-size: 0.61rem;
    letter-spacing: 0.12rem;
    margin-bottom: 1.4rem;
  }

  > div {
    width: 3.9rem;
    height: 3.9rem;
    background-color: ${props => (props.accomplished ? '#4CAF50' : '#f2f2f2')};
    transition: background-color 0.4s ease;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    > p {
      width: 0.12rem;
      height: 1.22rem;
      background-color: ${props =>
        props.accomplished ? '#4CAF50' : '#f2f2f2'};
      transition: background-color 0.4s ease;
      position: absolute;
      top: -1.22rem;
      left: 50%;
      transform: translateX(-50%);
    }

    > div {
      width: 3.3rem;
      height: 3.3rem;
      border-radius: 50%;
      outline: ${props =>
        props.accomplished ? '2px solid #FFFEFC' : '2px solid #b7b7b7'};
      transition: outline 0.4s ease;
      display: flex;
      align-items: center;
      justify-content: center;

      > svg {
        stroke: ${props => (props.accomplished ? '#FFFEFC' : '#b7b7b7')};
        transition: stroke 0.4s ease;
      }
    }
  }
`;
