import * as React from 'react';
import { useCallback, useEffect, useRef } from 'react';
import { ButtonPointsProps, LineStepsProps } from './Line3Steps';

const Line4Steps: React.FC<LineStepsProps> = ({
  onPointsCalculated,
  ...props
}) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const point1 = useRef<SVGCircleElement>(null);
  const point2 = useRef<SVGCircleElement>(null);
  const point3 = useRef<SVGCircleElement>(null);
  const point4 = useRef<SVGCircleElement>(null);

  const handlePointsCalculated = useCallback(() => {
    if (point1.current && point2.current && point3.current && point4.current) {
      const points = [
        point1.current,
        point2.current,
        point3.current,
        point4.current,
      ];
      const newPositionPoints: ButtonPointsProps[] = [];

      points.forEach(point => {
        if (!point.ownerSVGElement) {
          return;
        }

        const svgPoint = point.ownerSVGElement.createSVGPoint();
        svgPoint.x = point.cx.baseVal.value;
        svgPoint.y = point.cy.baseVal.value;
        const ctm = point.getCTM();

        if (!ctm) {
          return;
        }
        const transformedPoint = svgPoint.matrixTransform(ctm);

        newPositionPoints.push({
          x: transformedPoint.x,
          y: transformedPoint.y,
        });
      });

      onPointsCalculated(newPositionPoints);
    }
  }, [onPointsCalculated]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    handlePointsCalculated();

    if (svgRef.current) {
      const resizeObserver = new ResizeObserver(() => {
        handlePointsCalculated();
      });
      resizeObserver.observe(svgRef.current);
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [handlePointsCalculated]);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      xmlSpace="preserve"
      width="579.46899"
      height="96.267761"
      viewBox="0 0 579.46899 96.267761"
      {...props}
    >
      <g transform="translate(-751.172 -492.91)">
        <path
          d="M878.77 492.91c-25.599 0-46.417 21.084-46.417 47v1.816c0 19.152-15.394 34.738-34.312 34.738h-.446c-18.924 0-34.314-15.586-34.314-34.738h-12.11c0 25.909 20.826 46.992 46.424 46.992h.446c25.591 0 46.416-21.076 46.416-46.992v-1.816c0-19.16 15.394-34.739 34.312-34.739h1.795c18.917 0 34.313 15.586 34.313 34.739v1.816c0 25.909 20.827 46.992 46.418 46.992 25.592 0 46.416-21.076 46.416-46.992v-1.36c0-19.158 15.394-34.738 34.312-34.738s34.315 15.586 34.315 34.739v1.816c0 25.909 20.818 46.994 46.416 46.994l.01-.008c25.59 0 46.416-21.076 46.416-46.992h-12.112c0 19.152-15.394 34.738-34.312 34.738s-34.313-15.586-34.313-34.738v-1.816c0-25.91-20.826-46.994-46.418-46.994-25.59 0-46.416 21.078-46.416 46.994v1.359c0 19.152-15.394 34.738-34.312 34.738-18.917 0-34.313-15.586-34.313-34.738v-1.818c0-25.91-20.82-46.993-46.418-46.993zm324.712.408c-25.59 0-46.416 22.978-46.416 48.893l6.136.018 5.978-.058c0-19.16 15.384-36.591 34.302-36.591s34.313 15.586 34.313 34.738v1.816c0 25.91 20.82 46.993 46.418 46.993l.01-.006c25.59 0 46.418-21.077 46.418-46.993h-12.112c0 19.153-15.394 34.737-34.312 34.737s-34.313-15.584-34.313-34.737v-1.818c0-25.909-20.826-46.992-46.418-46.992z"
          style={{
            fill: '#1e88e5',
            fillOpacity: 1,
          }}
        />

        <circle
          ref={point1}
          cx={797.86}
          cy={541.913}
          r={1.061}
          style={{
            fill: '#1e88e5',
            fillOpacity: 1,
            strokeWidth: 20.48,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            paintOrder: 'stroke fill markers',
          }}
        />
        <circle
          ref={point2}
          cx={961.601}
          cy={541.913}
          r={1.061}
          style={{
            fill: '#1e88e5',
            fillOpacity: 1,
            strokeWidth: 20.48,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            paintOrder: 'stroke fill markers',
          }}
        />
        <circle
          ref={point3}
          cx={1123.191}
          cy={541.913}
          r={1.061}
          style={{
            fill: '#1e88e5',
            fillOpacity: 1,
            strokeWidth: 20.48,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            paintOrder: 'stroke fill markers',
          }}
        />

        <circle
          ref={point4}
          cx={1284.525}
          cy={541.913}
          r={1.061}
          style={{
            fill: '#1e88e5',
            fillOpacity: 1,
            strokeWidth: 20.48,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            paintOrder: 'stroke fill markers',
          }}
        />
      </g>
    </svg>
  );
};
export default Line4Steps;
