import { Form } from '@unform/web';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormHandles } from '@unform/core';
import {
  Container,
  Content,
  Logo,
  ButtonWrapper,
  AlertMessage,
  MessageWrapper,
} from './styles';
import { useModal } from '../../hooks/modal';
import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';
import Input from '../../components/Input';
import {
  AttentionIcon,
  LockIcon,
  ToolsIcon,
} from '../../assets/svg/SVGComponents';
import api from '../../services/api';
import IRequestError from '../../dtos/IRequestError';
import SecondaryButton from '../../components/Buttons/SecondaryButton';
import PrimaryButton from '../../components/Buttons/PrimaryButton';

interface ILicenseKeyData {
  key: string;
}

const AddHardwareUnlockModal: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { handleModal } = useModal();
  const [loading, setLoading] = useState(false);
  const { isSessionExpired } = useAuth();
  const { addToast } = useToast();
  const { t } = useTranslation();

  const handleClose = useCallback(() => {
    handleModal(null);
  }, [handleModal]);

  const handleSubmit = useCallback(
    async (formData: ILicenseKeyData) => {
      setLoading(true);

      api
        .get(`/keys/validate/${formData.key}`)
        .then(() => {
          setLoading(false);

          addToast({
            type: 'success',
            expression: 'Ok!',
            description: t('toasts.success.changes_saved'),
          });
          window.location.reload();
          handleClose();
          // adicionar perfil de ferramenta
        })
        .catch(async (err: IRequestError) => {
          setLoading(false);

          if (!(await isSessionExpired(err))) {
            addToast({
              type: 'error',
              expression: 'Ops.',
              description: t('toasts.errors.load_data_fail'),
            });
          }
        });

      addToast({
        type: 'error',
        expression: 'Ops.',
        description: t('toasts.wrong_key'),
      });
    },
    [addToast, handleClose, isSessionExpired, t],
  );

  return (
    <Container>
      <header>
        <h1>{t('h1.modal_unlock_Hardware')}</h1>
      </header>
      <Content>
        <div>
          <Logo>
            <ToolsIcon width="4rem" height="4rem" fill="#1188e2" />
          </Logo>
          <h3>
            Digite o código que você recebeu por e-mail para destravar sua nova
            ferramenta:
          </h3>
        </div>

        <Form ref={formRef} onSubmit={handleSubmit}>
          <div>
            {/* <span>{t('spans.label_hardware_unlock')}</span> */}
            <Input
              name="key"
              type="text"
              placeholder="Digite aqui o código"
              icon={LockIcon}
            />
          </div>

          <MessageWrapper>
            <AlertMessage>
              <AttentionIcon
                fill="none"
                width="1.6rem"
                stroke="#FF9800"
                strokeWidth={1.5}
              />
              <p>{t('p.unlock_hardware')}</p>
            </AlertMessage>
          </MessageWrapper>

          <ButtonWrapper>
            <SecondaryButton type="button" onClick={handleClose}>
              {t('buttons.cancel')}
            </SecondaryButton>
            <PrimaryButton type="submit" loading={loading}>
              {t('buttons.unlock_button_modal')}
            </PrimaryButton>
          </ButtonWrapper>
        </Form>
      </Content>
    </Container>
  );
};

export default AddHardwareUnlockModal;
