/* eslint-disable no-unused-vars */
enum UserGender {
  MALE = 'male',
  FEMALE = 'female',
  UNDECLARED = 'undeclared',
}

enum StepType {
  INTRO = 'intro',
  BUILDING = 'building',
  CIRCUIT = 'circuit',
  CODING = 'coding',
  BEHIND = 'behind-the-code',
  SIMULATOR = 'simulator',
  LEARN = 'what-did-you-learn',
  TEACHER = 'material-teacher',
}

enum ResourceType {
  MARKDOWN = 'markdown',
  CODING = 'coding',
  MAKECODE = 'tutorial_microbit',
  EMBEDING = 'embeding',
  NONE = 'none',
  SLIDE = 'slide',
  VIDEO = 'video',
}

enum UserRole {
  ADMIN = 'admin',
  MANAGER = 'manager',
  EDITOR = 'editor',
  TEACHER = 'teacher',
  STUDENT = 'student',
  GHOST = 'ghost',
}

enum UserStatus {
  ACTIVE = 'active',
  DISABLED = 'disabled',
  PENDING = 'pending',
}

enum SkillLevels {
  LEARNER = 'learner',
  PRO = 'pro',
  MASTER = 'master',
}

enum CategorySlugs {
  INTRODUCTORY = 'introductory',
  BNCC = 'bncc',
  STEAM = 'steam',
  COMMUNITY = 'community',
}

enum ActivityStatus {
  DRAFT = 'draft',
  PUBLISHED = 'published',
  COMPLETED = 'completed',
  ARCHIVED = 'archived',
}

enum SolutionStatus {
  DELIVERED = 'delivered',
  APPROVED = 'approved',
  CONTESTED = 'contested',
}

enum TutorialStatus {
  NOSTARTED = 'no_started',
  PROGRESS = 'progress',
  COMPLETED = 'completed',
}

enum BitName {
  POWER = 'Power',
  BUTTON = 'Button',
  ROLLERSWITCH = 'RollerSwitch',
  LIGHTSENSOR = 'LightSensor',
  DIMMER = 'Dimmer',
  SOUNDSENSOR = 'SoundSensor',
  WIRE = 'Wire',
  BRIGHTLED = 'BrightLED',
  BUZZER = 'Buzzer',
  BARGRAPH = 'Bargraph',
  DCMOTOR = 'DCMotor',
}

enum SolutionStatus {
  NOSTARTED = 'no_started',
  PROGRESS = 'progress',
  COMPLETED = 'completed',
  ANALYSIS = 'analysis',
}

enum BitCategory {
  POWER = 'Power',
  OUTPUT = 'Output',
  INPUT = 'Input',
  CONNECTION = 'Connection',
}

enum Skills {
  CATEGORY = 'bits_categories',
  FUNCTIONALITY = 'bits_functionalities',
  CONDITIONAL = 'conditional_logic',
  SEQUENTIAL = 'sequential_logic',
  VARIABLES = 'variables_identification',
  CIRCUIT = 'circuit_creation',
}

enum TypeProjects {
  PROJECT = 'fundamental 1',
  TUTORIAL = 'fundamental 2',
}

enum HardwareSlug {
  MICROBIT = 'micro_bit',
  FUZZYBITS = 'fuzzy_bits',
  MAKEYMAKEY = 'makey_makey',
  FUZZYPLUS = 'fuzzy_bits_plus',
  SCRATCH = 'scratch',
}

const statusTexts = {
  [UserStatus.ACTIVE]: 'Ativo',
  [UserStatus.DISABLED]: 'Desativado',
  [UserStatus.PENDING]: 'Cadastro pendente',
};

const genderTexts = {
  [UserGender.MALE]: 'Masculino',
  [UserGender.FEMALE]: 'Feminino',
  [UserGender.UNDECLARED]: 'Não declarado',
};

const skillLevelsTexts = {
  [SkillLevels.LEARNER]: 'Aprendiz',
  [SkillLevels.PRO]: 'PRO',
  [SkillLevels.MASTER]: 'Mestre',
};

const activityStatusTexts = {
  [ActivityStatus.DRAFT]: 'Rascunho',
  [ActivityStatus.PUBLISHED]: 'Publicado',
  [ActivityStatus.COMPLETED]: 'Finalizado',
  [ActivityStatus.ARCHIVED]: 'Arquivado',
};

const solutionStatusTexts = {
  [SolutionStatus.APPROVED]: 'Aprovada',
  [SolutionStatus.CONTESTED]: 'Contestada',
  [SolutionStatus.DELIVERED]: 'Entregue',
};

const skillsTexts = {
  [Skills.CATEGORY]: 'Categorias dos Bits',
  [Skills.FUNCTIONALITY]: 'Funções dos Bits',
  [Skills.CONDITIONAL]: 'Lógica Condicional',
  [Skills.SEQUENTIAL]: 'Lógica Sequencial',
  [Skills.VARIABLES]: 'Identificação de Variáveis',
  [Skills.CIRCUIT]: 'Criação de Circuits',
};

export {
  UserGender,
  UserRole,
  StepType,
  ResourceType,
  UserStatus,
  SkillLevels,
  CategorySlugs,
  ActivityStatus,
  SolutionStatus,
  BitName,
  TutorialStatus,
  BitCategory,
  Skills,
  TypeProjects,
  HardwareSlug,
  statusTexts,
  genderTexts,
  skillLevelsTexts,
  activityStatusTexts,
  solutionStatusTexts,
  skillsTexts,
};
