import React, {
  useRef,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import parsePhoneNumberFromString from 'libphonenumber-js';
import i18next from 'i18next';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getValidationErrors';
import {
  LockIcon,
  MailIcon,
  UserCircleIcon,
  LinkedinIcon,
  GenderIcon,
  WhatsupIcon,
} from '../../../assets/svg/SVGComponents';
import { useModal } from '../../../hooks/modal';
import { useToast } from '../../../hooks/toast';
import { useAuth, User } from '../../../hooks/auth';
import { genderTexts, UserGender } from '../../../utils/enums';
import Input from '../../../components/Input';
import SimpleSelect from '../../../components/Selects/SimpleSelect';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import ResetPasswordModal from '../../../modals/ResetPasswordModal';
import {
  MainContainer,
  RedefinePassword,
  Footer,
  genderSelectStyles,
} from './styles';
import IRequestError from '../../../dtos/IRequestError';
import MaskedInput from '../../../components/MaskedInput';
import PrivacyPolicyModal from '../../../modals/PrivacyPolicyModal';
import CheckboxUnform from '../../../components/CheckboxUnform';

interface ProfileFormData {
  name: string;
  email: string;
  phone?: string;
  linkedin?: string;
  gender: UserGender;
  subjects?: string[];
  privacy_policy: boolean;
  cookies_policy: boolean;
}

const ProfileForm: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { handleModal } = useModal();
  const { addToast } = useToast();
  const [privacyText, setPrivacyText] = useState<string>('');
  const { user, updateUser, isSessionExpired } = useAuth();
  const genderOptions = useMemo(() => {
    return Object.entries(genderTexts).map(keyValue => ({
      label: keyValue[1],
      value: keyValue[0],
    }));
  }, []);

  const numberMask = useMemo(() => {
    if (!user.phone) {
      return undefined;
    }
    const phoneNumber = parsePhoneNumberFromString(user.phone);

    if (phoneNumber && phoneNumber.isValid()) {
      return phoneNumber.formatNational();
    }

    return undefined;
  }, [user.phone]);

  const loadDescriptionMarkdown = useCallback(async () => {
    try {
      const response = await fetch(t('privacy-policy.privacy-cookies'));

      const text = await response.text();

      setPrivacyText(text);
    } catch (error) {
      addToast({
        type: 'error',
        expression: 'Ops!',
        description: t('toasts.errors.load_description_fail'),
      });
    }
  }, [addToast, t]);

  useEffect(() => {
    // Attach the listeners on component mount.
    if (i18next.language === 'en') {
      loadDescriptionMarkdown();
    }

    if (i18next.language === 'pt-BR') {
      loadDescriptionMarkdown();
    }
    setPrivacyText(t('privacy-policy.privacy'));
  }, [loadDescriptionMarkdown, t]);

  const handleResetPasswordModal = useCallback(() => {
    handleModal(<ResetPasswordModal />);
  }, [handleModal]);

  const handlePolicyPrivacyModal = useCallback(() => {
    handleModal(<PrivacyPolicyModal text={privacyText} />);
  }, [handleModal, privacyText]);

  const handleSubmit = useCallback(
    async (data: ProfileFormData) => {
      formRef.current?.setErrors({});
      setLoading(true);

      try {
        const profileSchema = Yup.object().shape({
          name: Yup.string().required(t('yup_errors.name_required')),
          email: Yup.string()
            .required(t('yup_errors.email_required'))
            .email(t('yup_errors.invalid_email')),
          phone: Yup.string()
            .notRequired()
            .test('is-valid-phone', 'Número de telefone inválido', value => {
              if (!value) return true;

              const phoneNumber = parsePhoneNumberFromString(value, 'BR');
              return phoneNumber ? phoneNumber.isValid() : false;
            }),
          linkedin: Yup.string().url(),
          gender: Yup.string()
            .oneOf(Object.values(UserGender), t('yup_errors.gender_not_found'))
            .required(t('yup_errors.gender_required')),
          privacy_policy: Yup.boolean()
            .oneOf([true], t('yup_errors.privacy_required'))
            .required(t('yup_errors.privacy_required')),
          cookies_policy: Yup.boolean().required(
            t('yup_errors.privacy_required'),
          ),
        });

        const validatedSchema = await profileSchema.validate(data, {
          abortEarly: false,
          stripUnknown: true,
        });

        const unmaskedPhone = data.phone?.replace(/\D/g, '');

        if (validatedSchema && unmaskedPhone) {
          const phoneNumber = parsePhoneNumberFromString(unmaskedPhone, 'BR');

          let formattedPhone = '';

          if (phoneNumber && phoneNumber.isValid()) {
            formattedPhone = phoneNumber.number; // Número no formato E.164
            validatedSchema.phone = formattedPhone;
          } else {
            addToast({
              type: 'error',
              expression: 'Ops!',
              description: t('toasts.errors.updated_data_fail'),
            });
          }
        }

        const response = await api.put<User>(`/user-profile`, validatedSchema);

        setLoading(false);
        updateUser(response.data);

        addToast({
          type: 'success',
          expression: 'Ok!',
          description: t('toasts.success.changes_saved'),
        });
      } catch (err: IRequestError | unknown) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        } else if (!(await isSessionExpired(err as IRequestError))) {
          addToast({
            type: 'error',
            expression: 'Ops!',
            description: t('toasts.errors.updated_data_fail'),
          });
        }
      }
    },
    [updateUser, addToast, t, isSessionExpired],
  );

  return (
    <MainContainer>
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        initialData={{
          name: user.name,
          email: user.email,
          phone: numberMask,
          password: '******',
          linkedin: user.linkedin,
          gender: genderOptions.find(
            genderOption => genderOption.value === user.gender,
          ),
          privacy_policy: user.privacy_policy,
          cookies_policy: user.cookies_policy,
        }}
      >
        <header>
          <h3>{t('h3.registration_data')}</h3>
          <PrimaryButton type="submit" loading={loading}>
            {t('buttons.save_changes')}
          </PrimaryButton>
        </header>

        <h4>{t('labels.teacher_name')}</h4>
        <Input
          name="name"
          type="text"
          placeholder={t('placeholders.full_name')}
          icon={UserCircleIcon}
        />

        <h4>{t('labels.phone')}</h4>
        <MaskedInput
          name="phone"
          placeholder={t('placeholders.phone')}
          icon={WhatsupIcon}
          mask="(99) 99999-9999"
          value={undefined}
        />

        <h4>{t('labels.email')}</h4>
        <Input
          name="email"
          type="text"
          placeholder={t('placeholders.email')}
          icon={MailIcon}
          readOnly
          disabled
        />

        <RedefinePassword>
          <h4>{t('labels.password')}</h4>
          <button type="button" onClick={handleResetPasswordModal}>
            {t('buttons.reset_password')}
          </button>
        </RedefinePassword>
        <Input
          name="password"
          placeholder={t('placeholders.password')}
          type="password"
          icon={LockIcon}
          readOnly
          disabled
        />

        <h4>{t('labels.linkedin')}</h4>
        <Input
          name="linkedin"
          type="text"
          placeholder="https://linkedin.com/in/example/"
          icon={LinkedinIcon}
        />

        <h4>{t('labels.gender')}</h4>
        <SimpleSelect
          name="gender"
          placeholder={t('placeholders.gender')}
          options={genderOptions}
          styles={genderSelectStyles}
          iconLeft={GenderIcon}
        />

        <CheckboxUnform name="privacy_policy" isBoolean>
          {t('navlinks.accept_policies_and_terms')}
          <button
            type="button"
            onClick={() => handlePolicyPrivacyModal()}
            style={{
              cursor: 'pointer',
              color: '#1E88E5',
              marginLeft: '0.4rem',
            }}
          >
            {t('navlinks.policies_and_terms')}
          </button>
        </CheckboxUnform>

        <CheckboxUnform name="cookies_policy" isBoolean>
          {t('navlinks.cookies_and_privacy')}
        </CheckboxUnform>

        <Footer>
          <PrimaryButton type="submit" loading={loading}>
            {t('buttons.save_changes')}
          </PrimaryButton>
        </Footer>
      </Form>
    </MainContainer>
  );
};

export default ProfileForm;
