import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import i18next from 'i18next';

import ReCAPTCHA from 'react-google-recaptcha';
import Checkbox from '../../../components/Checkbox';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getValidationErrors';

import BackgroundBLue from '../../../assets/images/backgroundSignIn.jpg';

import {
  FuzzyLogoNewOrange,
  LockIcon,
  MailIcon,
  UserCircleIcon,
} from '../../../assets/svg/SVGComponents';

import Input from '../../../components/Input';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import PrivacyPolicyModal from '../../../modals/PrivacyPolicyModal';

import {
  Container,
  Content,
  SideContent,
  Footer,
  ImageArea,
  TipBackground,
  LanguageButtons,
  TopContent,
} from './styles';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import { useModal } from '../../../hooks/modal';
import CheckboxUnform from '../../../components/CheckboxUnform';

interface SignUpFormData {
  name: string;
  email: string;
  password: string;
  password_confirmation: string;
  privacy_policy: boolean;
  cookies_policy: boolean;
  recaptcha: boolean;
}

const SignUp: React.FC = () => {
  const { handleModal } = useModal();
  const formRef = useRef<FormHandles>(null);
  const [captcha, setCaptcha] = useState<string | null>('');
  const history = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [privacyText, setPrivacyText] = useState<string>('');
  const [checkPrivacyPolicy, setCheckPrivacyPolicy] = useState(false);
  const { t } = useTranslation();
  const { signIn } = useAuth();
  const { addToast } = useToast();

  const recaptchaRef = useRef(null);

  const handlePolicyPrivacyModal = useCallback(() => {
    handleModal(<PrivacyPolicyModal text={privacyText} />);
  }, [handleModal, privacyText]);

  const loadDescriptionMarkdown = useCallback(async () => {
    try {
      const response = await fetch(t('privacy-policy.privacy-cookies'));

      const text = await response.text();

      setPrivacyText(text);
    } catch (error) {
      addToast({
        type: 'error',
        expression: 'Ops!',
        description: t('toasts.errors.load_description_fail'),
      });
    }
  }, [addToast, t]);

  useEffect(() => {
    // Attach the listeners on component mount.
    if (i18next.language === 'en') {
      loadDescriptionMarkdown();
    }

    if (i18next.language === 'pt-BR') {
      loadDescriptionMarkdown();
    }
    setPrivacyText(t('privacy-policy.privacy'));
  }, [loadDescriptionMarkdown, t]);

  // useEffect(() => {

  // }, [buttonSubmit]);

  const handleSubmit = useCallback(
    async (data: SignUpFormData) => {
      formRef.current?.setErrors({});
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: Unreachable code error
      recaptchaRef.current?.execute();
      const formattedFormData = {
        name: data.name,
        email: data.email,
        password: data.password,
        password_confirmation: data.password_confirmation,
        privacy_policy: data.privacy_policy,
        cookies_policy: data.privacy_policy,
        recaptcha: captcha,
      };

      console.log(data);

      try {
        setIsLoading(true);
        const signUpSchema = Yup.object().shape({
          name: Yup.string().required(t('yup_errors.name_required')),
          email: Yup.string()
            .required(t('yup_errors.email_required'))
            .email(t('yup_errors.invalid_email')),
          password: Yup.string().min(6, t('yup_errors.password_lenght')),
          password_confirmation: Yup.string()
            .oneOf([Yup.ref('password')], t('yup_errors.passwords_match'))
            .required(t('yup_errors.password_confirmation_required')),
          privacy_policy: Yup.boolean().oneOf(
            [true],
            t('yup_errors.privacy_required'),
          ),
          cookies_policy: Yup.boolean()
            .oneOf([true], t('yup_errors.privacy_required'))
            .required(t('yup_errors.privacy_required')),
        });

        const validated = await signUpSchema.validate(formattedFormData, {
          abortEarly: false,
        });

        console.log(validated);

        await api.post('/users/signup', {
          name: data.name,
          email: data.email,
          password: data.password,
          password_confirmation: data.password_confirmation,
          privacy_policy: data.privacy_policy,
          cookies_policy: data.privacy_policy,
        });

        await signIn({ email: data.email, password: data.password });

        history('/home');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }
        addToast({
          type: 'error',
          expression: 'Ops.',
          description: t('toasts.errors.signup_fail'),
        });
      } finally {
        setIsLoading(false);
      }
    },
    [captcha, t, signIn, history, addToast],
  );

  const handleLanguageChange = useCallback((lang_code: string) => {
    i18next.changeLanguage(lang_code);
  }, []);

  const handleCaptcha = useCallback(async (token: string | null) => {
    setCaptcha(token);
  }, []);

  if (!process.env.REACT_APP_RECAPTCHA_KEY) {
    throw new Error(
      'A chave do ReCAPTCHA não está definida nas variáveis de ambiente.',
    );
  }

  return (
    <Container>
      <Content>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <FuzzyLogoNewOrange />

          <h1>
            <Trans i18nKey="h1.teacher_signup" />
          </h1>

          <span>{t('labels.teacher_name')}</span>
          <Input
            name="name"
            type="text"
            placeholder={t('placeholders.full_name')}
            icon={UserCircleIcon}
          />

          <span>{t('labels.email')}</span>
          <Input
            name="email"
            type="text"
            placeholder={t('placeholders.email')}
            icon={MailIcon}
          />

          <span>{t('labels.password')}</span>
          <Input
            name="password"
            placeholder={t('placeholders.password')}
            type="password"
            icon={LockIcon}
            hasToggleButton
          />

          <span>{t('labels.password_confirmation')}</span>
          <Input
            name="password_confirmation"
            placeholder={t('placeholders.password_confirmation')}
            type="password"
            icon={LockIcon}
            hasToggleButton
          />

          <CheckboxUnform name="privacy_policy" isBoolean className="checkbox">
            {t('navlinks.accept_policies_and_terms')}
            <button
              type="button"
              onClick={() => handlePolicyPrivacyModal()}
              style={{
                cursor: 'pointer',
                color: '#1E88E5',
                marginLeft: '0.4rem',
              }}
            >
              {t('navlinks.policies_and_terms')}
            </button>
          </CheckboxUnform>

          <ReCAPTCHA
            className="captcha"
            ref={recaptchaRef}
            size="invisible"
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
            onChange={handleCaptcha}
          />

          <PrimaryButton type="submit" loading={isLoading}>
            {t('buttons.signup')}
          </PrimaryButton>
          <Footer>
            <Link to="/">{t('navlinks.signin')}</Link>
          </Footer>
        </Form>
        <footer />
      </Content>
      <SideContent>
        <ImageArea>
          <img src={BackgroundBLue} alt="fuzzy-logo" />
          <TopContent>
            <LanguageButtons>
              <button
                type="button"
                className={
                  i18next.language === 'pt-BR' ? 'active-language' : ''
                }
                onClick={() => handleLanguageChange('pt-BR')}
              >
                PT
              </button>
              |
              <button
                type="button"
                className={i18next.language === 'en' ? 'active-language' : ''}
                onClick={() => handleLanguageChange('en')}
              >
                EN
              </button>
            </LanguageButtons>
            <TipBackground>
              <img
                src="https://fuzzyplaydev.s3.us-east-2.amazonaws.com/backgroundTip.png"
                alt="tip-bg"
              />
            </TipBackground>
          </TopContent>
        </ImageArea>
      </SideContent>
    </Container>
  );
};

export default SignUp;
