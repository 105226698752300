import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactInputMask, { Props as InputMaskProps } from 'react-input-mask';
import { useField } from '@unform/core';
import {
  Container,
  InputAndErrorWrapper,
  InputError,
  ReadStatus,
} from './styles';

interface Props extends InputMaskProps {
  name: string;
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  iconRight?: React.FC<React.SVGProps<SVGSVGElement>>;
  saveAnswer?: () => void;
}

const MaskedInput: React.FC<Props> = ({
  name,
  icon: Icon,
  iconRight: IconRight,
  saveAnswer,
  ...rest
}) => {
  const [isFilled, setIsFilled] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    if (saveAnswer) saveAnswer();
    setIsFilled(!!inputRef.current?.value);
  }, [saveAnswer]);

  useEffect(() => {
    if (inputRef.current) {
      registerField({
        name: fieldName,
        ref: inputRef.current,
        getValue: ref => {
          return ref.value;
        },
        setValue: (ref, value) => {
          ref.value = value;
        },
        clearValue: ref => {
          ref.value = '';
        },
      });
    }
  }, [fieldName, registerField]);

  return (
    <InputAndErrorWrapper>
      {error && <InputError>{error}</InputError>}
      <Container isFilled={isFilled} isFocused={isFocused}>
        {Icon && <Icon width="1.33rem" fill="none" strokeWidth={1.5} />}

        <ReactInputMask
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          defaultValue={defaultValue}
          {...rest}
        >
          {(inputProps: any) => <input {...inputProps} ref={inputRef} />}
        </ReactInputMask>

        {IconRight && (
          <ReadStatus>
            <IconRight
              width="1.33rem"
              fill="none"
              stroke="#323232"
              strokeWidth={1.5}
            />
          </ReadStatus>
        )}
      </Container>
    </InputAndErrorWrapper>
  );
};

export default MaskedInput;
