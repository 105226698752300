import styled from 'styled-components';

interface CheckProps {
  isFocused: boolean;
  check: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  position: relative;

  > span {
    margin-left: 0.89rem;
  }
`;

export const InputError = styled.p`
  text-align: right;
  font-size: 0.88rem;
  color: #ff2e3e;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
`;

export const CheckboxContainer = styled.div`
  cursor: pointer;
  display: flex;
  place-content: center;
  position: relative;

  > input {
    width: 1.11rem;
    height: 1.11rem;
    border: 0;
    position: absolute;
    cursor: pointer;
    opacity: 0;
  }
`;

export const CheckboxIcon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

export const StyledCheckbox = styled.label<CheckProps>`
  display: inline-block;
  width: 1.11rem;
  height: 1.11rem;
  border: 0.5px solid ${props => (props.isFocused ? '#FF9800' : '#e2e2e2')};
  border: ${props => (props.check ? '0' : '1px solid #e2e2e2')};
  background: ${props => (props.check ? '#FF9800' : '#fff')};
  border-radius: 0.167rem;
  transition: all 0.3s;
  /*
  input:focus + &:hover {
    box-shadow: 0 0 0 3px pink;
  } */
  ${CheckboxIcon} {
    visibility: ${props => (props.check ? 'visible' : 'hidden')};
  }
`;
