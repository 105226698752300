import i18next from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import { useToast } from '../../../hooks/toast';

import { Container, ImageArea, TextContent } from './styles';

const PrivacyPolicy: React.FC = () => {
  const [descriptionText, setDescriptionText] = useState('');
  const { t } = useTranslation();
  const { addToast } = useToast();

  const loadDescriptionMarkdown = useCallback(async () => {
    try {
      const response = await fetch(t('privacy-policy.privacy-cookies'));

      const text = await response.text();

      setDescriptionText(text);
    } catch (error) {
      addToast({
        type: 'error',
        expression: 'Ops!',
        description: t('toasts.errors.load_description_fail'),
      });
    }
  }, [addToast, t]);

  useEffect(() => {
    // Attach the listeners on component mount.
    if (i18next.language === 'en') {
      loadDescriptionMarkdown();
    }

    if (i18next.language === 'pt-BR') {
      loadDescriptionMarkdown();
    }
    setDescriptionText(t('privacy-policy.privacy'));
  }, [loadDescriptionMarkdown, t]);

  return (
    <Container>
      <ImageArea>
        <img
          src="https://fuzzyplaydev.s3.us-east-2.amazonaws.com/Grupo+10640.png"
          alt="fuzzy-logo"
        />
      </ImageArea>
      <TextContent>
        <ReactMarkdown
          source={descriptionText}
          plugins={[gfm]}
          className="markdown-container"
        />
      </TextContent>
      <PrimaryButton className="primary-button">Voltar</PrimaryButton>
    </Container>
  );
};
export default PrivacyPolicy;
