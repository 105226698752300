import React, { useCallback } from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import PrimaryButton from '../../components/Buttons/PrimaryButton';
import { useModal } from '../../hooks/modal';

import { Container, TextContent, Logo } from './styles';
import { FuzzyLogoNewOrange } from '../../assets/svg/SVGComponents';

interface PrivacypolicyTextProps {
  text: string;
}

const PrivacyPolicyModal: React.FC<PrivacypolicyTextProps> = text => {
  const { handleModal } = useModal();

  const privacy = text;

  const handleClose = useCallback(() => {
    handleModal(null);
  }, [handleModal]);

  return (
    <Container>
      <Logo>
        <FuzzyLogoNewOrange />
      </Logo>
      <TextContent>
        <div>
          <ReactMarkdown plugins={[gfm]} className="markdown-container">
            {privacy.text}
          </ReactMarkdown>
        </div>
      </TextContent>

      <PrimaryButton
        onClick={() => {
          handleClose();
        }}
      >
        Voltar
      </PrimaryButton>
    </Container>
  );
};
export default PrivacyPolicyModal;
