import i18next from 'i18next';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MarkdownIt from 'markdown-it';
import { useSpring } from 'react-spring';
import { useToast } from '../../../../hooks/toast';
import IActivityProps from '../../../../dtos/IActivitiesProps';
import { ResourceType, StepType } from '../../../../utils/enums';
import {
  Container,
  FloatingVideo,
  TextContent,
  VideoPlayer,
  VideoWrapper,
} from './styles';
import IStepProps from '../../../../dtos/IStepsProps';

const keywordColors: { [key: string]: string } = {
  basic: '#1e90ff',
  input: '#d400d4',
  music: '#e63022',
  led: '#5c2d91',
  radio: '#E3008C',
  variables: '#DC143C',
  game: '#007A4B',
  logic: '#00A4A6',
};

const md = new MarkdownIt();

md.use(markdown => {
  const defaultRender = markdown.renderer.rules.code_inline;

  markdown.renderer.rules.code_inline = (tokens, idx, options, env, self) => {
    const token = tokens[idx];
    let color = '';
    const text = token.content.replace(
      /\|\|(.*?):(.+?)\|\|/g,
      (match, p1, p2) => {
        color = keywordColors[p1.trim()] || 'transparent';
        token.attrJoin('style', `background-color: ${color}; `);
        return `${p2}`;
      },
    );
    token.content = text;
    token.markup = '';
    return defaultRender
      ? defaultRender(tokens, idx, options, env, self)
      : token.content;
  };
});

interface IComponentProps {
  currentActivity: IActivityProps;
  currentStep: IStepProps;
}

const MarkdownComponent: React.FC<IComponentProps> = ({
  currentStep: step,
  currentActivity,
}) => {
  const [descriptionText, setDescriptionText] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const slice = location.pathname.split('/');
  const active_tab = slice[slice.length - 1] as StepType;
  const [isFloating, setIsFloating] = useState(false);
  const videoRef = useRef<HTMLIFrameElement>(null);
  const floatVideoRef = useRef<HTMLIFrameElement>(null);
  const [mainVideoPlayed, setMainVideoPlayed] = useState(false);
  const [floatingVideoPlayed, setFloatingVideoPlayed] = useState(false);
  const [isAutoplay, setAutoplay] = useState(false);
  const isScrollinfo = useRef(false);

  const { addToast } = useToast();
  const { t } = useTranslation();

  const loadDescriptionMarkdown = useCallback(
    async (currentStep: IStepProps) => {
      let descriptionURL = '';

      if (currentStep && step.resourse_type === ResourceType.MARKDOWN) {
        if (i18next.language === 'en') {
          descriptionURL = step.resource_url_en;
        }

        if (i18next.language === 'pt-BR') {
          descriptionURL = step.resource_url;
        }
      }

      if (!descriptionURL) {
        // Não prossiga se a URL não foi definida
        return;
      }

      try {
        const response = await fetch(descriptionURL);

        const text = await response.text();

        const html = md.render(text);

        setDescriptionText(html);
      } catch (error) {
        addToast({
          type: 'error',
          expression: 'Ops!',
          description: t('toasts.errors.load_description_fail'),
        });
      }
    },
    [addToast, step, t],
  );

  useEffect(() => {
    if (step) {
      loadDescriptionMarkdown(step);
    }
  }, [loadDescriptionMarkdown, step]);

  const handleAccesVideoButton = useCallback(() => {
    if (currentActivity && active_tab) {
      if (currentActivity.resource_url != null) {
        navigate(currentActivity.resource_url);
      }
    }
  }, [active_tab, currentActivity, navigate]);

  // Verifica se é necessário mostrar o video flutuante
  useEffect(() => {
    const handleScroll = (): void => {
      if (videoRef.current) {
        const rect = videoRef.current.getBoundingClientRect();

        isScrollinfo.current = true;

        if (rect.top < -100) {
          setIsFloating(true);
          setAutoplay(true);
        }

        if (rect.top > -100) {
          setIsFloating(false);
          setAutoplay(false);
        }
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleClick = (): void => {
      if (isScrollinfo.current) {
        isScrollinfo.current = false;
        setAutoplay(false);
      }
    };

    window.addEventListener('click', handleClick);
    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, []);

  // Verificando se o video foi iniciado pelo usuário
  useEffect(() => {
    const handleMessage = (event: MessageEvent): void => {
      const { data } = event;

      if (videoRef.current && videoRef.current.contentWindow) {
        if (event.source === videoRef.current.contentWindow) {
          if (data.message === 'panda_play') {
            setMainVideoPlayed(true);
          }

          if (data.message === 'panda_pause') {
            setMainVideoPlayed(false);
          }
        }

        if (floatVideoRef.current && floatVideoRef.current.contentWindow) {
          if (event.source === floatVideoRef.current.contentWindow) {
            if (data.message === 'panda_play') {
              setFloatingVideoPlayed(true);
            }

            if (data.message === 'panda_pause') {
              setFloatingVideoPlayed(false);
            }
          }
        }
      }
    };
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    if (
      isScrollinfo.current === true &&
      videoRef.current &&
      floatVideoRef.current &&
      videoRef.current.contentWindow &&
      floatVideoRef.current.contentWindow
    ) {
      if (isAutoplay && mainVideoPlayed) {
        floatVideoRef.current.contentWindow.postMessage({ type: 'play' }, '*');
        videoRef.current.contentWindow.postMessage({ type: 'pause' }, '*');
      }

      if (!isAutoplay && floatingVideoPlayed) {
        floatVideoRef.current.contentWindow.postMessage({ type: 'pause' }, '*');
        videoRef.current.contentWindow.postMessage({ type: 'play' }, '*');
      }
    }
  }, [floatingVideoPlayed, isAutoplay, mainVideoPlayed]);

  const floatVideoStyle = useSpring({
    transform: isFloating ? 'scale(1)' : 'scale(0)',
    config: { tension: 300 },
  });

  return (
    <Container>
      <VideoWrapper>
        {step?.optional_resource_url && (
          <VideoPlayer>
            <iframe
              id="main-player"
              ref={videoRef}
              title="panda-96686bbc-e03e-4ae2-8981-e5c1046eaed7"
              src={step?.optional_resource_url}
              allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture"
              allowFullScreen
              width="100%"
              height="100%"
            />
          </VideoPlayer>
        )}
      </VideoWrapper>

      <FloatingVideo style={floatVideoStyle}>
        <div>
          <iframe
            ref={floatVideoRef}
            title="panda-96686bbc-e03e-4ae2-8981-e5c1046eaed7"
            id="panda-96686bbc-e03e-4ae2-8981-e5c1046eaed7"
            src={step?.optional_resource_url}
            allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture"
            allowFullScreen
            width="100%"
            height="100%"
          />
        </div>
      </FloatingVideo>

      <TextContent>
        {currentActivity && (
          <div>
            <div
              className="markdown-container"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: descriptionText }}
            />
          </div>
        )}
      </TextContent>
    </Container>
  );
};

export default MarkdownComponent;
